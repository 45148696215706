(function( doc ) {
	'use strict';

    let modals = [];

    class Modal {
        constructor( el ) {
            this.container = el;
            this.content = el.querySelector( '.modal-content' );
            this.target = el.getAttribute( 'data-modal' );
            this.btnClose = el.querySelector( '.btn-modal-close' );

            this.addEventListeners();
        }

        addEventListeners() {
            this.container.addEventListener( 'click', _ => this.close() );
            this.content.addEventListener( 'click', e => e.stopPropagation() );
            this.btnClose.addEventListener( 'click', _ => this.close() );
        }

        show() {
            doc.body.classList.add( 'modal-in' );
        }

        close() {
            doc.body.classList.remove( 'modal-in' );
        }
    }

    app.$.forEach( '.modal-container', el => {
        modals.push( new Modal( el ) );
    } );

    app.$.forEach( '.modal-trigger', trigger => {
        trigger.addEventListener( 'click', e => {
            let target = trigger.getAttribute( 'data-modal-target' );

            modals.forEach( modal => {
                if ( target === modal.target ) {
                    modal.show();
                }
            } );

            e.preventDefault();
        } );
    } );

}( document ) );
(function( doc ) {
	'use strict';

	class Tabs {
		constructor( el ) {
			this.container = el;
			this.links = el.querySelectorAll( '.tab-link' );
			this.tabs = el.querySelectorAll( '.tab' );

			this.activeLink = el.querySelector( '.tab-link.active' );
			this.activeTab = el.querySelector( '.tab.active' );

			this.addEventListeners = this.addEventListeners.bind( this );
			this.switchTab = this.switchTab.bind( this );

			this.addEventListeners();
		}

		addEventListeners() {
			app.$.forEach( this.links, link => {
				link.addEventListener( 'click', this.switchTab );
			} );
		}

		switchTab( e ) {
			let link = e.target;

			if ( !link.classList.contains( 'active' ) ) {
				let target = link.getAttribute( 'href' ).substring( 1 );
				let targetTab = this.container.querySelector( '#tab-' + target );

				this.activeLink.classList.remove( 'active' );
				this.activeTab.classList.remove( 'active' );

				link.classList.add( 'active' );
				this.activeLink = link;
				targetTab.classList.add( 'active' );
				this.activeTab = targetTab;
			}

			e.preventDefault();
		}
	}
	
	app.Tabs = Tabs;

}( document ) );
///<reference path="../main.js">
/**
 * Copyright 2015 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
( function( doc ) {
	'use strict';

	var MaterialButton = function MaterialButton( element ) {
		this.element_ = element;
		this.init();
	};

	window.MaterialButton = MaterialButton;

	// Definte css classes
	MaterialButton.prototype.cssClasses_ = {
		RIPPLE_BUTTON: 'btn-ripple',
		RIPPLE_CONTAINER: 'btn-ripple-container',
		RIPPLE: 'btn-ripple-element',
		RIPPLE_CENTER: 'btn-ripple-center',
		RIPPLE_IS_ANIMATING: 'is-animating',
		RIPPLE_IS_VISIBLE: 'is-visible'
	};

	MaterialButton.prototype.RippleConstant = {
		INITIAL_SCALE: 'scale(0.0001, 0.0001)',
		INITIAL_SIZE: '1px',
		INITIAL_OPACITY: '0.4',
		FINAL_OPACITY: '0',
		FINAL_SCALE: ''
	};

	// initialize the element
	MaterialButton.prototype.init = function() {
		if ( this.element_ ) {
			
			// if this button needs the ripple effect
			//   add the necessary ripple elements and events
			if ( this.element_.classList.contains( this.cssClasses_.RIPPLE_BUTTON ) ) {
				this.initRipple();
			}
		}
	};

	MaterialButton.prototype.initRipple = function() {
		var recentering;

		// first add the elements
		this.addRippleElements();

		// set defaults and add event handlers
		recentering = this.element_.classList.contains( this.cssClasses_.RIPPLE_CENTER );
		this.frameCount_ = 0;
		this.rippleSize_ = 0;
		this.x_ = 0;
		this.y_ = 0;

		// Touch start produces a compat mouse down event, which would cause a
		// second ripple. To avoid that, we use this property to ignore the first
		// mouse down after a touch start.
		this.ignoringMouseDown_ = false;

		this.boundDownHandler = this.downHandler_.bind( this );
		this.element_.addEventListener( 'mousedown', this.boundDownHandler, false );
		this.element_.addEventListener( 'touchstart', this.boundDownHandler, false );

		this.boundUpHandler = this.upHandler_.bind( this );
		this.element_.addEventListener( 'mouseup', this.boundUpHandler, false );
		this.element_.addEventListener( 'mouseleave', this.boundUpHandler, false );
		this.element_.addEventListener( 'touchend', this.boundUpHandler, false );
		this.element_.addEventListener( 'blur', this.boundUpHandler, false );

		// helpers
		this.getFrameCount = function() {
			return this.frameCount_;
		};

		this.setFrameCount = function( fC ) {
			this.frameCount_ = fC;
		};

		this.getRippleElement = function() {
			return this.rippleElement_;
		};

		this.setRippleXY = function( newX, newY ) {
			this.x_ = newX;
			this.y_ = newY;
		};

		// styles
		this.setRippleStyles = function( start ) {
			if ( this.rippleElement_ !== null ) {
				var transformString, scale, size,
					offset = 'translate(' + this.x_ + 'px, ' + this.y_ + 'px)';

				if ( start ) {
					scale = this.RippleConstant.INITIAL_SCALE;
					size = this.RippleConstant.INITIAL_SIZE;
				}
				else {
					scale = this.RippleConstant.FINAL_SCALE;
					size = this.rippleSize_ + 'px';

					if ( recentering ) {
						offset = 'translate(' + this.boundWidth / 2 + 'px, ' + this.boundHeight / 2 + 'px)';
					}
				}

				transformString = 'translate(-50%, -50%) ' + offset + scale;

				this.rippleElement_.style.webkitTransform = transformString;
				this.rippleElement_.style.msTransform = transformString;
				this.rippleElement_.style.transform = transformString;

				if ( start ) {
					this.rippleElement_.classList.remove( this.cssClasses_.RIPPLE_IS_ANIMATING );
				}
				else {
					this.rippleElement_.classList.add( this.cssClasses_.RIPPLE_IS_ANIMATING );
				}
			}
		};

		// RAF
		this.animFrameHandler = function() {
			if ( this.frameCount_-- > 0 ) {
				requestAnimationFrame( this.animFrameHandler.bind( this ) );
			}
			else {
				this.setRippleStyles( false );
			}
		};
	};

	MaterialButton.prototype.addRippleElements = function() {
		var container = doc.createElement( 'span' );
		container.classList.add( this.cssClasses_.RIPPLE_CONTAINER );

		this.rippleElement_ = doc.createElement( 'span' );
		this.rippleElement_.classList.add( this.cssClasses_.RIPPLE );

		container.appendChild( this.rippleElement_ );

		this.boundRippleBlurHandler = this.blurHandler_.bind( this );
		this.rippleElement_.addEventListener( 'mouseup', this.boundRippleBlurHandler );
		this.element_.appendChild( container );
	};

	// blur event handler
	MaterialButton.prototype.blurHandler_ = function( e ) {
		if ( e ) {
			this.element_.blur();
		}
	};

	// disable the button
	MaterialButton.prototype.disable = function() {
		this.element_.disabled = true;
	};

	// button downHandler
	MaterialButton.prototype.downHandler_ = function( e ) {
		var bound, x, y, clientX, clientY;

		if ( !this.rippleElement_.style.width && !this.rippleElement_.style.height ) {
			var rect = this.element_.getBoundingClientRect();
			this.boundHeight = rect.height;
			this.boundWidth = rect.width;
			this.rippleSize_ = Math.sqrt( rect.width * rect.width + rect.height * rect.height ) * 2 + 2;
			this.rippleElement_.style.width = this.rippleSize_ + 'px';
			this.rippleElement_.style.height = this.rippleSize_ + 'px';
		}

		this.rippleElement_.classList.add( this.cssClasses_.RIPPLE_IS_VISIBLE );

		if ( e.type === 'mousedown' && this.ignoringMouseDown_ ) {
			this.ignoringMouseDown_ = false;
		}
		else {
			if ( e.type === 'touchstart' ) {
				this.ignoringMouseDown_ = true;
			}

			var frameCount = this.getFrameCount();
			if ( frameCount > 0 ) {
				return;
			}

			this.setFrameCount( 1 );

			bound = e.currentTarget.getBoundingClientRect();

			// Check if we are handling a keyboard click.
			if ( e.clientX === 0 && e.clientY === 0 ) {
				x = Math.round( bound.width / 2 );
				y = Math.round( bound.height / 2 );
			} else {
				clientX = e.clientX ? e.clientX : e.touches[0].clientX;
				clientY = e.clientY ? e.clientY : e.touches[0].clientY;
				x = Math.round( clientX - bound.left );
				y = Math.round( clientY - bound.top );
			}

			this.setRippleXY( x, y );
			this.setRippleStyles( true );

			window.requestAnimationFrame( this.animFrameHandler.bind( this ) );
		}
	};

	// button upHandler
	MaterialButton.prototype.upHandler_ = function( e ) {
		// Don't fire for the artificial "mouseup" generated by a double-click.
		if ( e && e.detail !== 2 ) {
			this.rippleElement_.classList.remove( this.cssClasses_.RIPPLE_IS_VISIBLE );
		}

		// Allow a repaint to occur before removing this class, so the animation
		// shows for tap events, which seem to trigger a mouseup too soon after mousedown.
		window.setTimeout( function() {
			this.rippleElement_.classList.remove( this.cssClasses_.RIPPLE_IS_VISIBLE );
		}.bind( this ), 0 );
	};

	// enable the button
	MaterialButton.prototype.enable = function() {
		this.element_.disabled = false;
	};

	app.$.forEach( '.btn-ripple', function( btn ) {
		btn.MaterialButton = new MaterialButton( btn );
	} );
}( document ) );
///<reference path="../main.js">
/**
 * Copyright 2016 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
( function( doc ) {
	'use strict';

	const ckEditorSettings = {
		allowedContent: true,
		height: 350,
		toolbar: 'Simple'
	};

	const formCssClasses = {
		chosenSelect: 'chosen-select',
		ckeditor: 'use-ckeditor',
		errorLabel: 'error-label',
		hidden: 'hidden',
		inputField: 'input-field',
		invalid: 'invalid',
		mediumEditor: 'use-medium-editor',
		required: 'req'
	};

	class Form {
		// When creating a new Form, we can pass in a selected element (i.e. doc.querySelect( '#form' ))
		// or we can pass in a selector (i.e. '#form' )
		constructor( el ) {
			if ( typeof el === 'string' ) {
				el = app.$( el );
			}
			
			this.container = el;
			this.fields = el.querySelectorAll( 'input:not([type="file"]),textarea,select' );
			this.reqFields = el.querySelectorAll( '.' + formCssClasses.required );
			this.inputFields = el.querySelectorAll( '.' + formCssClasses.inputField );

			this.initFormElements = this.initFormElements.bind( this );
			this.initEditors = this.initEditors.bind( this );
			this.checkActiveInputs = this.checkActiveInputs.bind( this );
			this.validateFields = this.validateFields.bind( this );
			this.collectData = this.collectData.bind( this );
			this.setFieldValues = this.setFieldValues.bind( this );
			this.clearForm = this.clearForm.bind( this );

			this.initFormElements();
			this.initEditors();
		}

		// This function will loop through all input field elements to check for
		// inputs and select elements to create our custom TextBox or Select objects
		initFormElements() {
			app.$.forEach( this.inputFields, container => {
				let select = container.querySelector( 'select' );
				let input = container.querySelector( 'input' );
				
				if ( select && !select.Select ) {
					select.Select = new app.Select( select );
				}

				if ( !input ) {
					input = container.querySelector( 'textarea' );
				}

				if ( !input ) {
					input = container.querySelector( '.' + formCssClasses.mediumEditor );
				}

				if ( input ) {
					let tag = input.tagName.toLowerCase();
					let type = input.type.toLowerCase();

					if ( !input.TextBox && type !== 'checkbox' && type !== 'radio' ) {
						input.TextBox = new app.TextBox( input );
					}
				}
			} );
		}

		// Helper functions to initialize textareas with MediumEditor or CKEDITOR
		// depending on specified class
		initEditors() {
			app.$.forEach( this.fields, field => {
				if ( field.classList.contains( formCssClasses.mediumEditor ) ) {
					if ( !window.MediumEditor ) {
						console.warn( 'MediumEditor source not found. Unable to use MediumEditor.' );
					}
					else {
						new MediumEditor( field, {
							placeholder: {
								text: ''
							}
						} );
					}
				}
				else if ( field.classList.contains( formCssClasses.ckeditor ) ) {
					if ( !window.CKEDITOR ) {
						console.warn( 'CKEDITOR source not found. Unable to use CKEDITOR' );
					}
					else {
						CKEDITOR.replace( field.id, ckEditorSettings );

						if ( field.classList.contains( 'ckfinder' ) ) {
							CKFinder.setupCKEditor( CKEDITOR.instances[field.id], '/ckfinder/' );
						}
					}
				}
			} );
		}

		// Check if inputs/selects have values to set or remove active class
		// on the sibling label
		checkActiveInputs() {
			app.$.forEach( this.inputFields, container => {
				let lbl = container.querySelector( 'label' );
				let select = container.querySelector( 'select' );
				let input = container.querySelector( 'input' );

				if ( lbl ) {
					lbl.classList.remove( 'active' );
				}

				if ( select && select.Select ) {
					select.Select.checkForValue();
				}

				if ( !input ) {
					input = container.querySelector( 'textarea' );
				}

				if ( !input ) {
					input = container.querySelector( '.' + formCssClasses.mediumEditor );
				}

				if ( input && input.TextBox ) {
					input.TextBox.checkForValue();
				}
			} );
		}

		// Helper function to check if required fields have valid data
		validateFields() {
			let isValid = true;

			app.$.forEach( this.reqFields, field => {
				let val = '';
				let tag = field.tagName.toLowerCase();

				if ( field.classList.contains( formCssClasses.mediumEditor ) ) {
					val = field.innerHTML;
				}
				else if ( field.classList.contains( formCssClasses.ckeditor ) ) {
					val = CKEDITOR.instances[field.id].getData().trim();
				}
				else if ( tag === 'select' ) {
					if ( field.Select ) {
						val = field.Select.getValue();
					}
					else if ( !field.classList.contains( formCssClasses.chosenSelect ) ) {
						val = field.options[field.selectedIndex].value;
					}
				}
				else {
					val = field.value.trim();
				}

				if ( val === '' || val === '-1' || val === -1 ) {
					field.classList.add( formCssClasses.invalid );
					isValid = false;
				}
				else {
					field.value = val;
					field.classList.remove( formCssClasses.invalid );
				}
			} );

			return isValid;
		}

		// Helper function to collect data from form fields and return as
		// JSON key/value pair object. Uses the element's name attribute as the key.
		collectData() {
			let params = {};

			app.$.forEach( this.fields, field => {
				let key = field.getAttribute( 'name' );
				let val = '';
				let tag = field.tagName.toLowerCase();
				let type = field.type ? field.type.toLowerCase() : '';

				if ( type === 'checkbox' ) {
					val = field.checked;
				}
				else if ( field.classList.contains( formCssClasses.mediumEditor ) ) {
					val = field.innerHTML;
				}
				else if ( field.classList.contains( formCssClasses.ckeditor ) ) {
					val = CKEDITOR.instances[field.id].getData().trim();
				}
				else if ( tag === 'select' ) {
					if ( field.Select ) {
						val = field.Select.getValue();
					}
					else if ( !field.classList.contains( formCssClasses.chosenSelect ) ) {
						val = field.options[field.selectedIndex].value;
					}
				}
				else {
					val = field.value.trim();

					if ( field.classList.contains( 'integer' ) ) {
						val = parseInt( val, 10 );
					}
					else if ( field.classList.contains( 'decimal' ) ) {
						val = parseFloat( val );
					}
				}

				params[key] = val;
			} );

			return params;
		}

		// Helper function to set the values of form fields. Will use the 
		// name attribute of each field element to select the respective value
		// from the obj parameter.
		setFieldValues( obj ) {
			app.$.forEach( this.fields, field => {
				let val = obj[field.getAttribute( 'name' )];
				let type = field.type ? field.type.toLowerCase() : '';
				let tag = field.tagName.toLowerCase();

				if ( !obj ) {
					console.warn( 'Property does not exist for key ' + field.getAttribute( 'name' ) );
				}
				else {
					if ( type === 'checkbox' ) {
						field.checked = val;
					}
					else if ( field.classList.contains( formCssClasses.mediumEditor ) ) {
						field.innerHTML = val;
					}
					else if ( field.classList.contains( formCssClasses.ckeditor ) ) {
						val = CKEDITOR.instances[field.id].setData( val );
					}
					else if ( tag === 'select' && field.Select ) {
						field.Select.setValue( val );
					}
					else {
						if ( field.classList.contains( 'input-date' ) ) {
							if ( obj[field.getAttribute( 'name' ) + 'Str'] ) {
								val = obj[field.getAttribute( 'name' ) + 'Str'];
							}
							else {
								val = moment( val ).format( 'MM/DD/YYYY' );
							}
						}

						field.value = val;
					}
				}
			} );

			this.checkActiveInputs();
		}

		// Helper function to clear out the values of a form. Will additionally
		// remove all HTML from containers with class .row-preview and hide
		// all elements with class .btn-item-upload-delete. It will then
		// run checkActiveInputs to reset the labels.
		clearForm() {
			app.$.forEach( this.fields, field => {
				let lbl = field.querySelector( '.' + formCssClasses.errorLabel );
				let type = field.type ? field.type.toLowerCase() : '';
				let tag = field.tagName.toLowerCase();

				if ( type === 'checkbox' ) {
					field.checked = false;
				}
				else if ( field.classList.contains( formCssClasses.mediumEditor ) ) {
					field.innerHTML = '';
				}
				else if ( tag === 'textarea' ) {
					CKEDITOR.instances[field.id].setData( '' );
				}
				else if ( tag === 'select' ) {
					if ( field.Select ) {
						field.Select.setValue( '-1' );
					}
					else if ( field.multiple && field.classList.contains( formCssClasses.chosenSelect ) ) {
						app.$.forEach( field.querySelectorAll( 'option' ), function( opt ) {
							opt.selected = false;
						} );

						jQuery( field ).trigger( 'chosen:updated' );
					}
					else {
						field.value = '-1';
					}
				}
				else {
					field.value = '';
				}

				field.classList.remove( formCssClasses.invalid );

				if ( lbl ) {
					field.parentNode.removeChild( lbl );
				}
			} );

			app.$.forEach( '.row-preview', row => {
				row.innerHTML = '';
			}, this.container );

			app.$.forEach( '.btn-item-upload-delete', btn => {
				btn.classList.add( 'hidden' );
			}, this.container );

			this.checkActiveInputs();
		}

		hide() {
			this.container.classList.add( formCssClasses.hidden );
		}

		show() {
			this.checkActiveInputs();
			this.container.classList.remove( formCssClasses.hidden );
		}
	}

	// Expose the Form object to the rest of the project
	app.Form = Form;
}( document ) );
///<reference path="../main.js">
/**
 * Copyright 2016 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
( function( doc ) {
	'use strict';

	const cssClasses = {
		ERROR_LABEL: 'error-label',
		INVALID_REQ_FIELD: 'invalid',
		MD_MENU: 'md-select',
		MENU: 'md-select-menu',
		MENU_OPEN: 'active',
		LIST_ITEM: 'md-select-menu-item',
		LIST_ITEM_HOVER: 'keyover',
		REQUIRED_FIELD: 'req'
	};

	class Select {
		constructor( select ) {
			// if on a touch device, default to native select
			if ( 'ontouchstart' in doc.documentElement && app.$.mq( '(max-width:1024px)' ) ) {
				return;
			}

			// elements
			this.select = select;
			this.select.classList.add( cssClasses.MD_MENU );
			this.container = this.select.parentNode;
			this.label = this.container.querySelector( 'label' );
			this.menu = null;

			// if this is a multiple select
			this.multiple = this.select.multiple;

			this.open = false;
			this.currentIndex = 0;

			this.listItems = [];

			//this.onChange = function() { };

			this.createMenu = this.createMenu.bind( this );
			this.setMenuPosition = this.setMenuPosition.bind( this );
			this.showMenu = this.showMenu.bind( this );
			this.hideMenu = this.hideMenu.bind( this );
			this.changeHandler = this.changeHandler.bind( this );
			this.focusHanlder = this.focusHanlder.bind( this );
			this.blurHandler = this.blurHandler.bind( this );
			this.keyDownHandler = this.keyDownHandler.bind( this );
			this.bodyClickHandler = this.bodyClickHandler.bind( this );
			this.checkIfRequired = this.checkIfRequired.bind( this );

			this.createMenu();
			this.addEventListeners();
		}

		addEventListeners() {
			this.select.addEventListener( 'focus', this.focusHanlder, false );
			this.select.addEventListener( 'blur', this.blurHandler, false );
		}

		// add change event
		setChangeEvent( fn ) {
			this.onChange = fn;
		}

		// create the drop down
		createMenu() {
			let opts = this.select.children;
			let len = opts.length;
			let i;
			let opt;
			let listItem;
			let li;

			this.menu = doc.createElement( 'ul' );
			this.menu.classList.add( cssClasses.MENU );

			// loop through all of the options and create the ListItems
			for ( i = 0; i < len; i++ ) {
				opt = opts[i];

				listItem = new ListItem( opt, i, this );
				this.listItems.push( listItem );
				this.menu.appendChild( listItem.listItem );
			}

			doc.body.appendChild( this.menu );

			this.setMenuPosition();
		}

		// reset the menu
		reloadMenu() {
			let container;

			if ( this.menu ) {
				container = this.menu.parentNode;
				container.removeChild( this.menu );
				this.listItems = [];
				this.createMenu();
			}
		}

		// set the menu position on the page
		setMenuPosition() {
			const rect = this.select.getBoundingClientRect();
			this.menu.style.top = rect.bottom + app.util.getWindowScrollPosition() + 'px';
			this.menu.style.left = rect.left + 'px';
			this.menu.style.width = this.select.offsetWidth + 'px';
		}

		// show the menu
		showMenu() {
			let me = this;

			// always start at the top of the list
			this.menu.scrollTop = 0;

			this.currentIndex = -1;
			this.menu.classList.add( cssClasses.MENU_OPEN );
			this.open = true;

			setTimeout( function() {
				doc.body.addEventListener( 'keydown', me.keyDownHandler, false );
				doc.body.addEventListener( 'click', me.bodyClickHandler, false );

				me.select.blur();
			}, 100 );
						
			// disable window from scrolling
			app.util.disableWindowScroll();
		}

		// hide the menu
		hideMenu() {
			let item;

			this.menu.classList.remove( cssClasses.MENU_OPEN );
			this.open = false;

			// make sure no listItems ahve the keyover class
			item = this.menu.querySelector( '.' + cssClasses.LIST_ITEM_HOVER );

			if ( item ) {
				item.classList.remove( cssClasses.LIST_ITEM_HOVER );
			}

			// remove event listeners
			doc.body.removeEventListener( 'keydown', this.keyDownHandler, false );
			doc.body.removeEventListener( 'click', this.bodyClickHandler, false );

			// re-enable window scrolling
			app.util.enableWindowScroll();
		}

		focusHanlder( e ) {
			this.setMenuPosition();
			this.showMenu();
		}

		blurHandler( e ) {
			
		}

		keyDownHandler( e ) {
			let index = this.currentIndex;
			let keyCode = -1;
			let menuScrollTop = this.menu.scrollTop;
			let menuHeight = this.menu.offsetHeight;
			let li;

			if ( e && e.keyCode ) {
				keyCode = e.keyCode;
				
				if ( keyCode === 9 ) {
					this.hideMenu();
					e.preventDefault();
				}

				// move down the list
				else if ( keyCode === 40 ) {
					e.preventDefault();
					this.currentIndex++;

					if ( this.currentIndex === this.listItems.length ) {
						this.currentIndex--;
						return;
					}

					if ( index >= 0 ) {
						this.listItems[index].listItem.classList.remove( cssClasses.LIST_ITEM_HOVER );
					}

					li = this.listItems[this.currentIndex].listItem;
					li.classList.add( cssClasses.LIST_ITEM_HOVER );

					// check scroll top
					if ( li.offsetTop + li.offsetHeight > menuScrollTop + menuHeight ) {
						this.menu.scrollTop = menuScrollTop + li.offsetHeight;
					}
				}

				// move up the list
				else if ( keyCode === 38 ) {
					e.preventDefault();
					this.currentIndex--;

					if ( this.currentIndex < 0 ) {
						this.currentIndex = -1;
						return;
					}

					if ( index >= 0 ) {
						this.listItems[index].listItem.classList.remove( cssClasses.LIST_ITEM_HOVER );
					}

					if ( this.currentIndex >= 0 ) {
						li = this.listItems[this.currentIndex].listItem;
						li.classList.add( cssClasses.LIST_ITEM_HOVER );

						// check scroll top
						if ( li.offsetTop < menuScrollTop ) {
							this.menu.scrollTop = menuScrollTop - li.offsetHeight;
						}
					}
				}

				// enter key is clicked
				else if ( keyCode === 13 ) {
					e.preventDefault();

					if ( this.currentIndex === -1 ) {
						this.currentIndex = 0;
					}

					// trigger list item click
					this.listItems[this.currentIndex].selectItem();
				}

				// if escape key is clicked
				else if ( keyCode === 27 ) {
					e.preventDefault();
					this.hideMenu();
				}
			}
		}

		changeHandler( e ) {
			if ( this.onChange && typeof this.onChange === 'function' ) {
				this.onChange();
			}
		}

		bodyClickHandler( e ) {
			if ( e && e.target && ( e.target === this.select || e.target.classList.contains( cssClasses.LIST_ITEM ) || e.target.classList.contains( 'btn-ripple-container' ) || e.target.classList.contains( 'btn-ripple-element' ) ) ) {
				// let the list item click event handle this
			}
			else {
				// clicked outside of the menu, hide the menu
				this.hideMenu();
			}
		}

		checkForValue() {

		}

		checkIfRequired() {
			let selected = this.menu.querySelector( '[selected="true"]' );
			let errorLbl = this.container.querySelector( '.' + cssClasses.ERROR_LABEL );
			let lbl;

			// remove any previous error messages
			if ( errorLbl ) {
				this.container.removeChild( errorLbl );
			}

			// if we don't have a selected option and this is a required field
			if ( !selected && this.select.classList.contains( cssClasses.REQUIRED_FIELD ) ) {
				lbl = doc.createElement( 'span ' );
				lbl.classList.add( cssClasses.ERROR_LABEL );
				lbl.textContent = 'Required field.';
				this.container.appendChild( lbl );
				this.select.classList.add( cssClasses.INVALID_REQ_FIELD );
			}

			// if we're all good, rmeove the invalid class
			else {
				this.select.classList.remove( cssClasses.INVALID_REQ_FIELD );
			}
		}

		setValue( value ) {
			let li = this.menu.querySelector( '[data-val="' + value + '"]' );
			let i = 0;
			let len = this.listItems.length;
			let listItem = null;

			if ( li ) {
				for ( i = 0; i < len; i++ ) {
					listItem = this.listItems[i];

					if ( listItem.listItem === li ) {
						if ( !listItem.listItem.getAttribute( 'selected' ) ) {
							listItem.selectItem();
						}
					}
				}
			}
		}

		getValue() {
			if ( !this.multiple ) {
				return this.select.options[this.select.selectedIndex].value;
			}
		}

		getTextValue() {
			if ( !this.multiple ) {
				return this.select.options[this.selectedIndex].text;
			}
		}
	}

	class ListItem {
		constructor( opt, index, select ) {
			this.listItem = doc.createElement( 'li' );
			this.select = select;
			this.index = index;

			this.listItem.classList.add( cssClasses.LIST_ITEM );
			this.listItem.classList.add( 'btn-ripple' );
			this.listItem.textContent = opt.textContent;
			this.listItem.setAttribute( 'data-val', opt.value );

			if ( opt.selected ) {
				this.listItem.setAttribute( 'selected', 'true' );
			}

			this.selectItem = this.selectItem.bind( this );

			this.addEventListeners();

			return this;
		}

		addEventListeners() {
			this.listItem.addEventListener( 'click', this.selectItem, false );
		}

		selectItem( e ) {
			let selected;
			
			// if clicking the currently selected item
			if ( this.listItem.getAttribute( 'selected' ) === 'true' ) {
				this.listItem.removeAttribute( 'selected' );

				if ( !this.select.multiple ) {
					this.select.select.value = '';
				}

				this.select.checkIfRequired();
			}
			else {
				// check if an item is already selected
				selected = this.select.menu.querySelector( '[selected="true"]' );

				// unselect a previously selected item if this is not a multiple select
				if ( selected && !this.select.multiple ) {
					selected.removeAttribute( 'selected' );
				}

				// select the selected item
				this.listItem.setAttribute( 'selected', 'true' );

				// update the selected element
				if ( !this.select.multiple ) {
					this.select.select.value = this.listItem.getAttribute( 'data-val' );
					this.select.checkIfRequired();
				}

				this.select.select.classList.remove( cssClasses.INVALID_REQ_FIELD );

				// hide the menu if this ins not a multiple select
				if ( !this.select.multiple ) {
					this.select.hideMenu();
				}
			}

			this.select.changeHandler();

			if ( e ) {
				e.stopPropagation();
			}
		}
	}

	app.Select = Select;

}( document ) );
///<reference path="../main.js">
/**
 * Copyright 2016 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 * 
 * Corresponding HTML should follow:
 * 
 * <div class="input-field">
 *   <input type="text" id="tb-mytb" name="dbCol" />
 *   <label for="tb-mytb">Label Text</label>
 * </div>
 * 
 * To make a field required, add class="req"
 * To make a field use a datepicker, add class="input-date"
 * 
 * To autovalidate email, set type="email"
 * To auotvalidate phone numbers, set type="tel" 
 */
( function( doc ) {
	'use strict';

	const cssClasses = {
		ACTIVE_FIELD_CLASS: 'active',
		DATE_SELECTOR: 'input-date',
		ERROR_LABEL: 'error-label',
		INVALID_REQ_FIELD: 'invalid',
		REQUIRED_FIELD: 'req'
	};

	// Regex's for validating field data
	const regExpressions = {
		EMAIL: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
		TEL: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
		DATE: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/
	};

	class TextBox {
		// Provide the input element for this TextBox
		constructor( input ) {
			// elements
			this.input = input;
			this.container = input.parentNode;
			this.label = this.container.querySelector( 'label' );

			// check for date
			if ( this.input.classList.contains( cssClasses.DATE_SELECTOR ) ) {
				this.initDatePicker();
			}

			// check for initial value
			this.checkForValue();

			this.initDatePicker = this.initDatePicker.bind( this );
			this.focusHandler = this.focusHandler.bind( this );
			this.blurHandler = this.blurHandler.bind( this );
			this.validateField = this.validateField.bind( this );
			this.checkForValue = this.checkForValue.bind( this );
			this.setValue = this.setValue.bind( this );
			this.getValue = this.getValue.bind( this );

			this.addEventListeners();
		}

		// Add the event listeners
		addEventListeners() {
			this.input.addEventListener( 'focus', this.focusHandler, false );
			this.input.addEventListener( 'blur', this.blurHandler, false );
			this.input.addEventListener( 'change', this.checkForValue, false );
		}

		// Initialize the date picker if the input has class .input-date
		initDatePicker() {
			let field = this.input;
			let picker;

			// make sure it is a text input
			field.type = 'text';

			// if touch device use default date picker
			if ( 'ontouchstart' in doc.documentElement ) {
				field.type = 'date';
				return;
			}

			picker = new Pikaday( {
				field: field,
				format: 'MM/DD/YYYY',
				onSelect: function() {
					field.value = this.getMoment().format( 'MM/DD/YYYY' );
				}
			} );
		}

		// OnFocus event handler
		focusHandler() {
			this.label.classList.add( cssClasses.ACTIVE_FIELD_CLASS );
		}

		// OnBlur event handler
		blurHandler() {
			this.checkForValue();
			this.validateField();
		}

		// Helper function to check for a value and handle the active class of the label
		checkForValue() {
			if ( this.getValue() !== '' ) {
				this.label.classList.add( cssClasses.ACTIVE_FIELD_CLASS );
				this.validateField();
			}
			else {
				this.label.classList.remove( cssClasses.ACTIVE_FIELD_CLASS );
			}
		}

		// Helper function to provide validation of field data based on input type and classes
		validateField() {
			let field = this.input;
			let type = field.type.toLowerCase();
			let valid = true;
			let val = this.getValue();
			let minChars = field.getAttribute( 'min-chars' );
			let prevError = this.container.querySelector( '.' + cssClasses.ERROR_LABEL );
			let lbl;
			let msg = '';

			// check for a value in a required field
			if ( field.classList.contains( cssClasses.REQUIRED_FIELD ) && val === '' ) {
				valid = false;
				msg = 'Required field.';
			}

			// check for min characters
			else if ( minChars && val.length < parseInt( minChars, 10 ) ) {
				valid = false;
				msg = minChars + ' characters required.';
			}

			// check for valid email address
			else if ( type === 'email' && !regExpressions.EMAIL.test( val ) ) {
				valid = false;
				msg = 'Invalid email address.';
			}

			// check for valid phone number
			else if ( type === 'tel' && !regExpressions.TEL.test( val ) ) {
				valid = false;
				msg = 'Inavlid phone number.';
			}

			// check for date
			else if ( field.classList.contains( cssClasses.DATE_SELECTOR ) && !regExpressions.DATE.test( val ) ) {
				valid = false;
				msg = 'Incorrect date format.';
			}

			// remove any previous error messages
			if ( prevError ) {
				this.container.removeChild( prevError );
			}

			// if the field is valid
			if ( valid ) {
				field.classList.remove( cssClasses.INVALID_REQ_FIELD );
			}

			// if invalid, make sure it is highlighted
			else {
				field.classList.add( cssClasses.INVALID_REQ_FIELD );

				// if we have a message
				if ( msg !== '' ) {
					lbl = doc.createElement( 'span' );
					lbl.classList.add( cssClasses.ERROR_LABEL );
					lbl.textContent = msg;
					this.container.appendChild( lbl );
				}
			}
		}

		// Helper function to set the value of the input
		setValue( val ) {
			this.input.value = val;
			this.checkForValue();
			this.validateField();
		}

		// Helper function get the value of the input
		getValue() {
			return this.input.value.trim();
		}
	}

	// Expose the TextBox object to the app
	app.TextBox = TextBox;

}( document ) );
'use strict';

(function( doc ) {

    class Router {
        constructor() {
            this.nav = app.$( '#nav-main' );

            if ( !this.nav ) {
                return;
            }

            this.links = this.nav.querySelectorAll( 'a' );

            this._onClick = this._onClick.bind( this );
            this._onChanged = this._onChanged.bind( this );
            this._updateNavLinks = this._updateNavLinks.bind( this );
            this._scrollToPage = this._scrollToPage.bind( this );
            this.go = this.go.bind( this );
            
            this.addEventListeners();
            this.checkLoadUrl();
        }

        addEventListeners() {   
            app.$.forEach( this.links, link => {
                link.addEventListener( 'click', this._onClick );
            } );

            window.addEventListener( 'popstate', this._onChanged );
        }

        checkLoadUrl() {
            app.$.forEach( this.links, link => {
                let navHref = link.href;

                if ( navHref === window.location.href ) {
                    this.go( link.getAttribute( 'href' ) );
                }
            } );
        }

        _onClick( e ) {
            e.preventDefault();
            let url = e.target.getAttribute( 'href' );
            this.go( url );
        }

        _onChanged( url ) {
            if ( doc.body.classList.contains( 'nav-in' ) ) {
                app.Nav.closeMenu();
            }

            this._updateNavLinks();
            this._scrollToPage( url );

            if ( typeof window.ga === 'function' ) {
                window.ga( 'send', 'pageview' );
            }
        }

        _updateNavLinks() {
            app.$.forEach( this.links, link => {
                link.classList.remove( 'active' );

                let navHref = link.href;
                
                if ( navHref === window.location.href ) {
                    link.classList.add( 'active' );
                }
            } );
        }

        _scrollToPage( url ) {
            let page = app.$( '[data-route="' + url + '"]' );
            
            if ( !app.scroll ) {
                setTimeout( () => {
                    this._scrollToPage( url );
                }, 100 );
            }
            else {
                try {
                    let y = page.offsetTop;

                    if ( app.$.mq( '(max-width:1023px)' ) ) {
                        y = y - app.$( '#masthead' ).offsetHeight;
                    }
                    
                    app.scroll.scrollToY( y );
                }
                catch( err ) {
                    console.log( 'Error:', err );
                }
            }
        }

        go( url ) {
            if ( window.location.href === url ) {
                return;
            }
            
            let state = {
                scrollY: window.scrollY
            };

            window.history.replaceState( state, null, window.location.href );

            window.history.pushState( null, null, url );
            return this._onChanged( url );
        }
    }

    new Router();
}( document ) );
///<reference path="../main.js">
/**
 * Copyright 2016 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
app.scroll = ( function( doc ) {
	'use strict';

	var hdr = doc.getElementById( 'hdr-main' ),
		links = doc.querySelectorAll( '.link-scroll' ),
		lastY = 0,

		SCROLL_TIME = 500;

	window.forEachElement( links, function( link ) {
		link.addEventListener( 'click', linkClick, false );
	} );

	function linkClick( e ) {
		var selector = this.getAttribute( 'data-scroll-to' ),
			page = doc.querySelector( '[data-scroll="' + selector + '"]' ),
			scrollToYCoord = 0;
		
		if ( page && !this.classList.contains( 'active' ) ) {
			if ( this.classList.contains( 'border' ) ) {
				this.classList.add( 'selected' );
			}

			lastY = app.util.getWindowScrollPosition();
			scrollToYCoord = page.offsetTop - hdr.offsetHeight;

			scrollToY( scrollToYCoord );

			if ( doc.body.classList.contains( 'nav-in' ) ) {
				doc.body.classList.remove( 'nav-in' );
			}

			e.preventDefault();
		}
	}

	function scrollToY( scrollTargetY ) {
		var scrollY = app.util.getWindowScrollPosition(),// window.scrollY,
			speed = SCROLL_TIME,
			easing = easing || 'easeOutSine',
			currentTime = 0,

			time = Math.max( 0.3, Math.min( Math.abs( scrollY - scrollTargetY ) / speed, 0.99 ) ),

			PI_D2 = Math.PI / 2,
			easingEquations = {
				easeOutSine: function( pos ) {
					return Math.sin( pos * ( Math.PI / 2 ) );
				},
				easeInOutSine: function( pos ) {
					return ( -0.5 * ( Math.cos( Math.PI * pos ) - 1 ) );
				},
				easeInOutQuint: function( pos ) {
					if ( ( pos /= 0.5 ) < 1 ) {
						return 0.5 * Math.pow( pos, 5 );
					}
					return 0.5 * ( Math.pow(( pos - 2 ), 5 ) + 2 );
				}
			};

		function tick() {
			currentTime += 1 / 60;

			var p = currentTime / time;
			var t = easingEquations[easing]( p );

			if ( p < 1 ) {
				requestAnimationFrame( tick );

				window.scrollTo( 0, scrollY + ( ( scrollTargetY - scrollY ) * t ) );
			} else {
				window.scrollTo( 0, scrollTargetY );
			}
		}

		tick();
	}

	return {
		scrollToY: scrollToY
	};

}( document ) );
///<reference path="../main.js">
/**
 * Copyright 2015 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
 app.util = (function( doc ) {
    'use strict';
        
    // will clone an object, not copying by reference
    function cloneObj( obj ) {
        return JSON.parse( JSON.stringify( obj ) );
    }

    function extend( obj1, obj2 ) {
        var obj = obj1;

        for ( var key in obj2 ) {
            obj[key] = obj2[key];
        }

        return obj;
    }

    function getWindowScrollPosition() {
        if ( typeof window.scrollY === 'undefined' ) {
            return document.documentElement.scrollTop;
        }
        else {
            return window.scrollY;
        }
    }

    function callBackFn( instance, fnName ) {
    	return function() {
    		instance[fnName].apply( instance, arguments );
    	};
    }

    function disableWindowScroll() {
    	doc.body.classList.add( 'noscroll' );
    }

    function enableWindowScroll() {
    	doc.body.classList.remove( 'noscroll' );
    }

    return {
        cloneObj: cloneObj,
        extend: extend,
        getWindowScrollPosition: getWindowScrollPosition,
        callBackFn: callBackFn,
        disableWindowScroll: disableWindowScroll,
        enableWindowScroll: enableWindowScroll
    };

}( document ) );
/**
* Copyright 2016 Select Interactive, LLC. All rights reserved.
* @author: The Select Interactive dev team (www.select-interactive.com)
*/
( function( doc ) {
    'use strict';

    let formEl = app.$( '#form-contact' );
    let form = new app.Form( formEl );
    let btnSubmit = app.$( '#btn-submit' );
    let formStatus = app.$( '#form-status' );
    let processing = false;

    btnSubmit.addEventListener( 'click', e => {
        if ( processing ) {
            return;
        }

        processing = true;
        
        if ( !form.validateFields() ) {
            formStatus.textContent = 'Fields marked with * are required.';
            processing = false;
            return;
        }

        btnSubmit.textContent = 'Submitting...';
        formStatus.textContent = '';
        let params = form.collectData();

        app.$.fetch( '/api/submitMineralSaleForm', {
            body: params
        } ).then( rsp => {
            if ( rsp.success ) {
                formEl.innerHTML = '<p>Thank you! Your submission has been received and we will be in touch the following business day. Should you have any questions in the interim, please call 817-928-3260 or email directly, <a href="mailto:info@tumbleweedroyalty.com">info@tumbleweedroyalty.com</a>.</p>';
                setTimeout( _ => {
                    doc.body.classList.remove( 'modal-in' );
                }, 500 );
            }
            else {  
                status.textContent = 'Unable to submit your information at this time. Please try again.';
                console.log( 'Error: ', rsp.msg || 'No error data' );
                processing = false;
                btnSubmit.textContent = 'Submit';
            }
        } );
    } );

}( document ) );
///<reference path="../main.js">
/**
 * Copyright 2016 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
(function( doc ) {
    'use strict';
        
    new app.Tabs( app.$( '#contact-tab-container' ) );

}( document ) );
/**
* Copyright 2016 Select Interactive, LLC. All rights reserved.
* @author: The Select Interactive dev team (www.select-interactive.com)
*/
( function( doc ) {
    'use strict';

    let navTrigger = app.$( '#btn-nav-trigger' );
    let icon = navTrigger.querySelector( 'i' );

    if ( navTrigger ) {
        navTrigger.addEventListener( 'click', toggleNav );
    }

    function toggleNav() {
        doc.body.classList.toggle( 'nav-in' );

        if ( doc.body.classList.contains( 'nav-in' ) ) {
            icon.textContent = 'close';
        }
        else {
            icon.textContent = 'menu';
        }
    }

    app.Nav = {};
    app.Nav.closeMenu = toggleNav;

}( document ) );